<template>
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow"
    :class="[
      {
        expanded:
          !isVerticalMenuCollapsed ||
          (isVerticalMenuCollapsed && isMouseHovered),
      },
      skin === 'semi-dark' ? 'menu-dark' : 'menu-light',
    ]"
    @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)"
  >
    <!-- main menu header-->

    <div
      class="navbar-header expanded text-center"
    >
      <slot
        name="header"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon"
      >
        <a
          href="/"
          class="d-flex justify-content-center"
        >
          <div class="d-flex justify-content-between align-items-center">
            <div class="pb-1">
              <img
                src="/logo.png"
                alt="logo"
                width="190px"
                :style="{ width: shallShadowBottom ? '50px' : null }"
              >
              <span
                v-if="shallShadowBottom"
                class="text-left"
                style="color:white !important;font-size:20px;"
              > HENG LOTTO</span>
            </div>
          </div>
        </a>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <div
      :class="{ 'd-block': shallShadowBottom }"
      class="shadow-bottom"
    />

    <!-- main menu content-->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="main-menu-content scroll-area"
      tagname="ul"

      @ps-scroll-y="
        evt => {
          shallShadowBottom = evt.srcElement.scrollTop > 0
        }
      "
    >
      <vertical-nav-menu-items
        :items="navMenuItems"
        class="navigation navigation-main"
      />
    </vue-perfect-scrollbar>
    <!-- /main menu content-->
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
// import { BLink } from 'bootstrap-vue'
import useAppConfig from '@core/app-config/useAppConfig'
import { $themeConfig } from '@themeConfig'
import { computed, provide, ref } from '@vue/composition-api'
import navMenuItems from '@/navigation/vertical'
import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue'
import useVerticalNavMenu from './useVerticalNavMenu'

export default {
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    // BLink,
    // BImg,
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props)

    const { skin } = useAppConfig()

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false)

    provide('isMouseHovered', isMouseHovered)

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'))

    // App Name
    const { appName, appLogoImage } = $themeConfig.app

    return {
      navMenuItems,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appLogoImage,
    }
  },
}
</script>
<style lang="scss" scoped>
  @import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@700&family=Russo+One&display=swap');
  .animate-charcter
  {
    font-family: 'Russo One', sans-serif;
     text-transform: uppercase;
    background-image: linear-gradient(
      -225deg,
      #ffffff 0%,
      #3871ec 29%,
      #5567a3 67%,
      #49649d 100%
    );
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    color: #fff;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 2s linear infinite;
    display: inline-block;
    // font-weight: 600 !important;
    font-size: 30px;
  }
  @keyframes textclip {
    to {
      background-position: 200% center;
    }
  }
  h3 {
    font-family: 'Russo One', sans-serif;
    margin-top: 5px;
    // font-weight: 600;
    font-size: 30px;
    color: #ffff
  }
  </style>

<style lang="scss">
@import '~@core/scss/base/core/menu/menu-types/vertical-menu.scss';
</style>
